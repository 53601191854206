import React from "react";
import HelpAndSupport from "../screens/Dashboard/Profile/HelpAndSupport";

const Home = React.lazy(() => import("../screens/Dashboard"));
const Task = React.lazy(() => import("../screens/Dashboard/Task"));
const Wallet = React.lazy(() => import("../screens/Dashboard/Wallet"));
const AllTaskStatus = React.lazy(() =>
  import("../screens/Dashboard/Task/AllTaskStatus/AllTaskStatus")
);
const Earning = React.lazy(() => import("../screens/Dashboard/Earnings"));
const RedeemCoupon = React.lazy(() =>
  import("../screens/Dashboard/Wallet/RedeemCoupon")
);
const RedeemINR = React.lazy(() =>
  import("../screens/Dashboard/Wallet/RedeemPoints/RedeemINR/RedeemINR")
);
const Referral = React.lazy(() =>
  import("../screens/Dashboard/Referral/Referral")
);
const Withdraw = React.lazy(() =>
  import("../screens/Dashboard/Wallet/withdraw")
);
// Social Media Task

const SocialMediaTask = React.lazy(() =>
  import("../screens/Dashboard/SocialMediaTask/SocialMediaTask")
);
const FacebookTask = React.lazy(() =>
  import("../screens/Dashboard/SocialMediaTask/FacebookTask")
);
const InstagramTask = React.lazy(() =>
  import("../screens/Dashboard/SocialMediaTask/InstagramTask")
);
const LinkedinTask = React.lazy(() =>
  import("../screens/Dashboard/SocialMediaTask/LinkedinTask")
);
const TelegramTask = React.lazy(() =>
  import("../screens/Dashboard/SocialMediaTask/TelegramTask")
);
const TwitterTask = React.lazy(() =>
  import("../screens/Dashboard/SocialMediaTask/TwitterTask")
);
const YoutubeTask = React.lazy(() =>
  import("../screens/Dashboard/SocialMediaTask/YoutubeTask")
);
// const WhatsAppTask = React.lazy(() =>
//   import("../screens/Dashboard/SocialMediaTask/WhatsAppTask")
// );
const ReviewTask = React.lazy(() =>
  import("../screens/Dashboard/SocialMediaTask/ReviewTask")
);
const OthersTask = React.lazy(() =>
  import("../screens/Dashboard/SocialMediaTask/OthersTask")
);
const TaskDetails = React.lazy(() =>
  import("../screens/Dashboard/SocialMediaTask/TaskDetails")
);
const Premium = React.lazy(() => import("../screens/Dashboard/Premium"));
const Success = React.lazy(() =>
  import("../screens/Dashboard/Premium/Payment Status/Success")
);
const Failiure = React.lazy(() =>
  import("../screens/Dashboard/Premium/Payment Status/Failiure")
);
const MyLeads = React.lazy(() => import("../screens/Dashboard/MyLeads"));
const AllPointHistory = React.lazy(() =>
  import("../screens/Dashboard/LeaderBoard/AllPointHistory/AllPointHistory")
);
const MyProfile = React.lazy(() =>
  import("../screens/Dashboard/Profile/MyProfile")
);
const ManagePayment = React.lazy(() =>
  import("../screens/Dashboard/Profile/ManagePayment/ManagePayment")
);
const KYC = React.lazy(() => import("../screens/Dashboard/Profile/KYC/KYC"));
const LeaderBoard = React.lazy(() =>
  import("../screens/Dashboard/LeaderBoard")
);
const MysteryBox = React.lazy(() =>
  import("../screens/Dashboard/LeaderBoard/MysteryBox")
);
const LeaderBoardHistory = React.lazy(() =>
  import("../screens/Dashboard/LeaderBoard/LeaderBoardHistory")
);
const CreateTask = React.lazy(() => import("../screens/Dashboard/CreateTask"));
const CreateTaskForm = React.lazy(() =>
  import("../screens/Dashboard/CreateTask/CreateTaskForm")
);
const DepositMethod = React.lazy(() =>
  import("../screens/Dashboard/Deposit/DepositMethod/DepositMethod")
);
const ShowHistory = React.lazy(() =>
  import("../screens/Dashboard/CreateTask/ShowHistory")
);

const ManageTask = React.lazy(() => import("../screens/Dashboard/ManageTask"));
const ManageCreatedTasks = React.lazy(() =>
  import("../screens/Dashboard/Task/ManageCreatedTasks/ManageCreatedTasks")
);
const AllSubmissions = React.lazy(() =>
  import("../screens/Dashboard/Task/AllSubmissions/AllSubmissions")
);

const PaymentMethod = React.lazy(() =>
  import("../screens/Dashboard/Premium/PaymentMethod/PaymentMethod")
);

const PremiumBankTransfer = React.lazy(() =>
  import("../screens/Dashboard/Premium/PaymentMethods/PremiumBankTransfer")
);
const PremiumUPITransfer = React.lazy(() =>
  import("../screens/Dashboard/Premium/PaymentMethods/PremiumUPITransfer")
);
const PremiumUSDTTransfer = React.lazy(() =>
  import("../screens/Dashboard/Premium/PaymentMethods/PremiumUSDTTransfer")
);

const RedeemPoints = React.lazy(() =>
  import("../screens/Dashboard/Wallet/RedeemPoints/RedeemPoints")
);
const ChangePassword = React.lazy(() =>
  import("../screens/Dashboard/Profile/ChangePassword/ChangePassword")
);

const About = React.lazy(() => import("../screens/About/About"));
const AboutUs = React.lazy(() => import("../screens/About/AboutUs/AboutUs"));
const PrivacyPolicy = React.lazy(() =>
  import("../screens/About/PrivacyPolicy/PrivacyPolicy")
);
const RefundPolicy = React.lazy(() =>
  import("../screens/About/RefundPolicy/RefundPolicy")
);
const TermsAndConditions = React.lazy(() =>
  import("../screens/About/TermsAndConditions/TermsAndConditions")
);
const ContactUs = React.lazy(() =>
  import("../screens/About/ContactUs/ContactUs")
);
const Feedback = React.lazy(() => import("../screens/About/Feedback/Feedback"));
// 404
const Page404 = React.lazy(() => import("../screens/Page404"));
const CreateTaskStatus = React.lazy(() =>
  import("../screens/Dashboard/CreateTask/CreateTaskStatus/CreateTaskStatus")
);
const CreatedTaskHistory = React.lazy(() =>
  import("../screens/Dashboard/Task/AllTaskHistory/CreatedTaskHistory")
);
const AddSocialMediaHandle = React.lazy(() =>
  import(
    "../screens/Dashboard/Profile/AddSocialMediaHandle/AddSocialMediaHandle"
  )
);

export const routers = [
  {
    path: "*",
    exact: true,
    name: "Error",
    permission: ["user"],
    component: Page404,
  },
  {
    path: "/",
    exact: true,
    name: "Dashboard",
    permission: ["user"],
    component: Home,
  },
  {
    path: "/task",
    exact: true,
    name: "Task",
    permission: ["user"],
    component: Task,
  },
  {
    path: "/wallet",
    exact: true,
    name: "Wallet",
    permission: ["user"],
    component: Wallet,
  },
  {
    path: "/earning",
    exact: true,
    name: "Earning",
    permission: ["user"],
    component: Earning,
  },
  {
    path: "/redeem-coupon",
    exact: true,
    name: "Redeem Coupon",
    permission: ["user"],
    component: RedeemCoupon,
  },
  {
    path: "/redeem-inr",
    exact: true,
    name: "Redeem INR",
    permission: ["user"],
    component: RedeemINR,
  },
  {
    path: "/referral",
    exact: true,
    name: "Referral",
    permission: ["user"],
    component: Referral,
  },
  {
    path: "/withdraw",
    exact: true,
    name: "Withdraw",
    permission: ["user"],
    component: Withdraw,
  },
  // Social Media Task

  {
    path: "/social-media-task",
    exact: true,
    name: "Social Media Task",
    permission: ["user"],
    component: SocialMediaTask,
  },
  {
    path: "/facebook-task",
    exact: true,
    name: "Facebook Task",
    permission: ["user"],
    component: FacebookTask,
  },
  {
    path: "/instagram-task",
    exact: true,
    name: "Instagram Task",
    permission: ["user"],
    component: InstagramTask,
  },
  {
    path: "/telegram-task",
    exact: true,
    name: "Telegram Task",
    permission: ["user"],
    component: TelegramTask,
  },
  {
    path: "/linkedin-task",
    exact: true,
    name: "Linkedin Task",
    permission: ["user"],
    component: LinkedinTask,
  },
  {
    path: "/youtube-task",
    exact: true,
    name: "Youtube Task",
    permission: ["user"],
    component: YoutubeTask,
  },
  {
    path: "/twitter-task",
    exact: true,
    name: "Twitter Task",
    permission: ["user"],
    component: TwitterTask,
  },
  // {
  //   path: "/whatsapp-task",
  //   exact: true,
  //   name: "WhatsApp Task",
  //   permission: ["user"],
  //   component: WhatsAppTask,
  // },
  {
    path: "/reviews-task",
    exact: true,
    name: "Review Task",
    permission: ["user"],
    component: ReviewTask,
  },
  {
    path: "/all-tasks-status",
    exact: true,
    name: "All Tasks Status",
    permission: ["user"],
    component: AllTaskStatus,
  },
  {
    path: "/others-task",
    exact: true,
    name: "Others Task",
    permission: ["user"],
    component: OthersTask,
  },
  {
    path: "/task-details",
    exact: true,
    name: "Task Details",
    permission: ["user"],
    component: TaskDetails,
  },
  {
    path: "/premium",
    exact: true,
    name: "Premium",
    permission: ["user"],
    component: Premium,
  },
  {
    path: "/premium/payment-method",
    exact: true,
    name: "Premium Payment Method",
    permission: ["user"],
    component: PaymentMethod,
  },
  {
    path: "/premium/bank-transfer",
    exact: true,
    name: "Premium Bank Transfer",
    permission: ["user"],
    component: PremiumBankTransfer,
  },
  {
    path: "/premium/upi-transfer",
    exact: true,
    name: "Premium UPI Transfer",
    permission: ["user"],
    component: PremiumUPITransfer,
  },
  {
    path: "/premium/usdt-transfer",
    exact: true,
    name: "Premium USDT Transfer",
    permission: ["user"],
    component: PremiumUSDTTransfer,
  },
  {
    path: "/premium/payment-status/success",
    exact: true,
    name: "Payment Successfull",
    permission: ["user"],
    component: Success,
  },
  {
    path: "/premium/payment-status/failiure",
    exact: true,
    name: "Payment Failed",
    permission: ["user"],
    component: Failiure,
  },
  {
    path: "/my-leads",
    exact: true,
    name: "My Leads",
    permission: ["user"],
    component: MyLeads,
  },
  {
    path: "/all-point-history",
    exact: true,
    name: "All Point History",
    permission: ["user"],
    component: AllPointHistory,
  },
  {
    path: "/my-profile",
    exact: true,
    name: "My Profile",
    permission: ["user"],
    component: MyProfile,
  },
  {
    path: "/help-and-support",
    exact: true,
    name: "Help and Support",
    permission: ["user"],
    component: HelpAndSupport,
  },
  {
    path: "/my-profile/manage-payment",
    exact: true,
    name: "Manage Payment",
    permission: ["user"],
    component: ManagePayment,
  },
  {
    path: "/my-profile/kyc",
    exact: true,
    name: "KYC",
    permission: ["user"],
    component: KYC,
  },
  {
    path: "/my-profile/change-password",
    exact: true,
    name: "Change Password",
    permission: ["user"],
    component: ChangePassword,
  },
  {
    path: "/leader-board",
    exact: true,
    name: "Leader Board",
    permission: ["user"],
    component: LeaderBoard,
  },
  {
    path: "/mystery-box",
    exact: true,
    name: "Mystery Box",
    permission: ["user"],
    component: MysteryBox,
  },
  {
    path: "/leader-board-history",
    exact: true,
    name: "Leader Board History",
    permission: ["user"],
    component: LeaderBoardHistory,
  },
  {
    path: "/promote",
    exact: true,
    name: "Create Task",
    permission: ["user"],
    component: CreateTask,
  },
  {
    path: "/promote/:taskProfile/:taskSubProfile/:autoFillLimit",
    exact: true,
    name: "Create Task",
    permission: ["user"],
    component: CreateTask,
  },
  {
    path: "/create-task-form",
    exact: true,
    name: "Create Task Form",
    permission: ["user"],
    component: CreateTaskForm,
  },
  {
    path: "/deposit-method",
    exact: true,
    name: "Deposit Method",
    permission: ["user"],
    component: DepositMethod,
  },
  {
    path: "/show-history",
    exact: true,
    name: "Show History",
    permission: ["user"],
    component: ShowHistory,
  },
  {
    path: "/manage-task",
    exact: true,
    name: "Manage Task",
    permission: ["user"],
    component: ManageTask,
  },
  {
    path: "/redeem-points",
    exact: true,
    name: "Redeem Points",
    permission: ["user"],
    component: RedeemPoints,
  },
  {
    path: "/about",
    exact: true,
    name: "About",
    permission: ["user"],
    component: About,
  },
  {
    path: "/about-us",
    exact: true,
    name: "About Us",
    permission: ["user"],
    component: AboutUs,
  },
  {
    path: "/privacy-policy",
    exact: true,
    name: "Privacy Policy",
    permission: ["user"],
    component: PrivacyPolicy,
  },
  {
    path: "/refund-policy",
    exact: true,
    name: "Refund Policy",
    permission: ["user"],
    component: RefundPolicy,
  },
  {
    path: "/terms-and-conditions",
    exact: true,
    name: "Terms And Conditions",
    permission: ["user"],
    component: TermsAndConditions,
  },
  {
    path: "/contact-us",
    exact: true,
    name: "Contact Us",
    permission: ["user"],
    component: ContactUs,
  },
  {
    path: "/feedback",
    exact: true,
    name: "Feedback",
    permission: ["user"],
    component: Feedback,
  },
  {
    path: "/create-task-status",
    exact: true,
    name: "Create Task Status",
    permission: ["user"],
    component: CreateTaskStatus,
  },
  {
    path: "/manage-created-tasks",
    exact: true,
    name: "Manage Created Tasks",
    permission: ["user"],
    component: ManageCreatedTasks,
  },
  {
    path: "/all-submissions",
    exact: true,
    name: "All Submissions",
    permission: ["user"],
    component: AllSubmissions,
  },
  {
    path: "/created-task-history",
    exact: true,
    name: "Created Task History",
    permission: ["user"],
    component: CreatedTaskHistory,
  },
  {
    path: "/my-profile/add-social-media-handle",
    exact: true,
    name: "Add Social Media Handle",
    permission: ["user"],
    component: AddSocialMediaHandle,
  },
];
